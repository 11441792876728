import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from './providers/ThemeProvider'
import { Login } from './pages/Auth/Login'
import { Register } from './pages/Auth/Register'
import { RegisterSuccess } from './pages/Auth/RegisterSuccess'
import { ForgotPassword } from './pages/Auth/ForgotPassword'
import { RecoveryPasswordSuccess } from './pages/Auth/RecoveryPasswordSuccess'

const App = () => (
  <ThemeProvider>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/register-success" element={<RegisterSuccess />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route
        path="/password-recovery-success"
        element={<RecoveryPasswordSuccess />}
      />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  </ThemeProvider>
)

export default App
