import React, { FC, ReactNode } from 'react'
import { LanguageSelect } from '../../components/FormControls'

export interface AuthLayoutProps {
  title: ReactNode
  description?: ReactNode
  children: ReactNode
}

export const AuthLayout: FC<AuthLayoutProps> = ({
  title,
  description,
  children,
}) => (
  <div className="flex-center h-full bg-neutral-800 text-white">
    <div className="flex-center w-109 flex-col rounded-2xl bg-secondary-800 px-10 pb-4 pt-10 shadow-elevation5">
      <img className="mb-14" src="/assets/images/logo.svg" alt="" />
      <h2 className={description ? 'mb-2' : 'mb-10'}>{title}</h2>
      {description && (
        <p className="typo-body2 mb-10 text-center text-secondary-400">
          {description}
        </p>
      )}

      {children}

      <div className="w-full">
        <hr className="mb-2 mt-8 border-secondary-600" />
        <LanguageSelect />
      </div>
    </div>
  </div>
)
