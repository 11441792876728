import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'

import { FormProvider, RHFTextField } from '../../components/RHFControls'

import { AuthLayout } from '../../layouts'
import useQueryValue from '../../hooks/useQueryValue'

type FormData = {
  code: string
}

export interface ForgotPasswordVerificationProps {
  onSubmit(verificationCode: string): void
}

export const ForgotPasswordVerification: FC<
  ForgotPasswordVerificationProps
> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const redirectTo = useQueryValue('redirectTo')

  const formSchema = yup.object().shape({
    code: yup.string().required(t('validation.required')),
  })

  const defaultValues = {
    code: '',
  }

  const methods = useForm<FormData>({
    resolver: yupResolver(formSchema),
    defaultValues,
  })

  const { handleSubmit } = methods

  const handleVerify = async (data: FormData) => {
    onSubmit(data.code)
  }

  return (
    <AuthLayout title={t('forgotPasswordVerification.title')}>
      <div className="w-full">
        <div className="typo-body2 mb-6 rounded-lg bg-primary-500/50 px-4 py-2 text-white">
          {t('forgotPasswordVerification.description')}
        </div>

        <FormProvider methods={methods} onSubmit={handleSubmit(handleVerify)}>
          <RHFTextField
            name="code"
            label={t('common.verificationCode')}
            fullWidth
          />

          <Button className="mt-6" type="submit" variant="contained" fullWidth>
            {t('common.next')}
          </Button>
        </FormProvider>

        <div className="mt-3 text-center">
          <Link
            className="typo-body2 text-white underline"
            to={`/login?redirectTo=${redirectTo}`}
          >
            {t('forgotPassword.backToLogin')}
          </Link>
        </div>
      </div>
    </AuthLayout>
  )
}
