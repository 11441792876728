import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'

import {
  FormProvider,
  RHFPasswordField,
  RHFTextField,
} from '../../components/RHFControls'

import { AuthLayout } from '../../layouts'
import { AuthService } from '../../services'
import { RegisterVerification } from './RegisterVerification'
import { passwordRegexp } from '../../types/utils'
import useQueryValue from '../../hooks/useQueryValue'

type FormData = {
  email: string
  password: string
  confirmPassword: string
}

export const Register = () => {
  const { t } = useTranslation()
  const redirectTo = useQueryValue('redirectTo')

  const [loading, setLoading] = useState(false)
  const [otpCode, setOtpCode] = useState<string>()

  const formSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('validation.invalidEmail'))
      .required(t('validation.required')),
    password: yup
      .string()
      .required(t('validation.required'))
      .min(8, t('validation.passwordLength', { length: 8 }))
      .matches(passwordRegexp, t('validation.passwordPattern')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), ''], t('validation.passwordMatch'))
      .required(t('validation.required')),
  })

  const defaultValues = {
    email: '',
    password: '',
    confirmPassword: '',
  }

  const methods = useForm<FormData>({
    resolver: yupResolver(formSchema),
    defaultValues,
  })

  const { setError, handleSubmit } = methods
  const formData = methods.watch()

  const handleRegister = async (data: FormData) => {
    setLoading(true)
    try {
      const { email, password } = data
      const response = await AuthService.register(email, password)
      setOtpCode(response.otp)
    } catch (error: any) {
      setError('confirmPassword', {
        ...error,
        message: error?.response?.status
          ? t('toast.registerFailed')
          : t('toast.networkError'),
      })
    }
    setLoading(false)
  }

  if (otpCode) {
    return (
      <RegisterVerification
        email={formData.email}
        password={formData.password}
        otpCode={otpCode}
      />
    )
  }

  return (
    <AuthLayout title={t('register.title')}>
      <div className="w-full">
        <FormProvider methods={methods} onSubmit={handleSubmit(handleRegister)}>
          <RHFTextField name="email" label={t('common.email')} fullWidth />

          <RHFPasswordField
            className="mt-4"
            name="password"
            label={t('common.password')}
          />

          <RHFPasswordField
            className="mt-4"
            name="confirmPassword"
            label={t('register.confirmPassword')}
          />

          <LoadingButton
            className="mt-6"
            type="submit"
            variant="contained"
            fullWidth
            loading={loading}
          >
            {t('common.next')}
          </LoadingButton>
        </FormProvider>

        <div className="flex-center typo-body2 mt-3 gap-2 text-center">
          <span className="text-sm text-neutral-400">
            {t('register.haveAccount')}
          </span>
          <Link
            className="text-white underline"
            to={`/login?redirectTo=${redirectTo}`}
          >
            {t('common.login')}
          </Link>
        </div>
      </div>
    </AuthLayout>
  )
}
