import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'

import { FormProvider, RHFTextField } from '../../components/RHFControls'

import useQueryValue from '../../hooks/useQueryValue'
import { AuthLayout } from '../../layouts'
import { AuthService } from '../../services'

type FormData = {
  code: string
}

export interface RegisterVerificationProps {
  email: string
  password: string
  otpCode: string
}

export const RegisterVerification: FC<RegisterVerificationProps> = ({
  email,
  password,
  otpCode,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const redirectTo = useQueryValue('redirectTo')

  const [loading, setLoading] = useState(false)

  const formSchema = yup.object().shape({
    code: yup.string().required(t('validation.required')),
  })

  const defaultValues = {
    code: '',
  }

  const methods = useForm<FormData>({
    resolver: yupResolver(formSchema),
    defaultValues,
  })

  const { setError, handleSubmit } = methods

  const handleVerify = async (data: FormData) => {
    setLoading(true)
    try {
      const { code } = data
      await AuthService.verifyEmail({
        email,
        password,
        otp: otpCode,
        code,
      })

      navigate(`/register-success?redirectTo=${redirectTo}`)
    } catch (error: any) {
      setError('code', {
        ...error,
        message: error?.response?.status
          ? t('toast.incorrectVerificationCode')
          : t('toast.networkError'),
      })
    }
    setLoading(false)
  }

  return (
    <AuthLayout
      title={t('registerVerification.title')}
      description={t('registerVerification.description')}
    >
      <div className="w-full">
        <FormProvider methods={methods} onSubmit={handleSubmit(handleVerify)}>
          <RHFTextField name="code" label={t('common.code')} fullWidth />

          <LoadingButton
            className="mt-6"
            type="submit"
            variant="contained"
            fullWidth
            loading={loading}
          >
            {t('common.submit')}
          </LoadingButton>
        </FormProvider>
      </div>
    </AuthLayout>
  )
}
