import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { AuthLayout } from '../../layouts'
import useQueryValue from '../../hooks/useQueryValue'

export const RegisterSuccess = () => {
  const { t } = useTranslation()
  const redirectTo = useQueryValue('redirectTo')

  return (
    <AuthLayout title={t('register.title')}>
      <div className="typo-body2 rounded-lg bg-primary-500/50 px-4 py-2 text-white">
        {t('register.successMessage')}
      </div>
      <Link
        className="typo-body2 mt-16 text-white underline"
        to={`/login?redirectTo=${redirectTo}`}
      >
        {t('register.goToLogin')}
      </Link>
    </AuthLayout>
  )
}
