import { HttpService } from './http.service'
import { CONFIG } from '../constants'
import {
  LoginResponseDto,
  OtpResponseDto,
  RegisterResponseDto,
  ResetPasswordDto,
  VerifyEmailDto,
  VerifyEmailResponseDto,
} from '../types/dtos'

const prefix = `${CONFIG.AUTH_SERVER}/auth`

export class AuthService {
  static login(email: string, password: string) {
    return HttpService.post<LoginResponseDto>(`${prefix}/generate-token`, {
      email,
      password,
    })
  }

  static register(email: string, password: string) {
    return HttpService.post<RegisterResponseDto>(`${prefix}/sign-up`, {
      email,
      password,
    })
  }

  static verifyEmail(data: VerifyEmailDto) {
    return HttpService.post<VerifyEmailResponseDto>(`${prefix}/sign-up`, data)
  }

  static getOtpResetPassword(email: string) {
    return HttpService.post<OtpResponseDto>(`${prefix}/set-password`, {
      email,
    })
  }

  static resetPassword(data: ResetPasswordDto) {
    return HttpService.post<OtpResponseDto>(`${prefix}/set-password`, data)
  }
}
