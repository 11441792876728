import React from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem, TextField } from '@mui/material'
import { LANGUAGES } from '../../constants'
import { Language } from '@mui/icons-material'

export const LanguageSelect = () => {
  const { i18n } = useTranslation()
  const { language, changeLanguage } = i18n

  return (
    <TextField
      select
      InputProps={{
        startAdornment: <Language className="fill-secondary-400" />,
      }}
      value={language}
      onChange={(e) => changeLanguage(e.target.value)}
      sx={{
        '.MuiInput-root': {
          background: 'transparent',
          border: 0,
          '.MuiSelect-select': {
            background: 'transparent',
            color: '#D1D1D1',
            paddingRight: '2.5rem !important',
          },
          '.MuiSelect-icon': {
            fill: '#ACACAC',
          },
        },
      }}
    >
      {LANGUAGES.map((option) => (
        <MenuItem key={option.code} value={option.code}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  )
}
