import React, { FC, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'

import { FormProvider, RHFPasswordField } from '../../components/RHFControls'

import useQueryValue from '../../hooks/useQueryValue'
import { AuthLayout } from '../../layouts'
import { AuthService } from '../../services'
import { passwordRegexp } from '../../types/utils'

type FormData = {
  password: string
  confirmPassword: string
}

export interface ResetPasswordProps {
  email: string
  otpCode: string
  verificationCode: string
}

export const ResetPassword: FC<ResetPasswordProps> = ({
  email,
  otpCode,
  verificationCode,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const redirectTo = useQueryValue('redirectTo')

  const [loading, setLoading] = useState(false)

  const formSchema = yup.object().shape({
    password: yup
      .string()
      .required(t('validation.required'))
      .min(8, t('validation.passwordLength', { length: 8 }))
      .matches(passwordRegexp, t('validation.passwordPattern')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), ''], t('validation.passwordMatch'))
      .required(t('validation.required')),
  })

  const defaultValues = {
    password: '',
    confirmPassword: '',
  }

  const methods = useForm<FormData>({
    resolver: yupResolver(formSchema),
    defaultValues,
  })

  const { setError, handleSubmit } = methods

  const handleVerify = async (data: FormData) => {
    setLoading(true)
    try {
      const { password } = data
      await AuthService.resetPassword({
        email,
        otp: otpCode,
        code: verificationCode,
        newPassword: password,
      })
      navigate(`/password-recovery-success?redirectTo=${redirectTo}`)
    } catch (error: any) {
      setError('confirmPassword', {
        ...error,
        message: error?.response?.status
          ? t('toast.resetPasswordFailed')
          : t('toast.networkError'),
      })
    }
    setLoading(false)
  }

  return (
    <AuthLayout
      title={t('resetPassword.title')}
      description={t('resetPassword.description', { email })}
    >
      <div className="w-full">
        <FormProvider methods={methods} onSubmit={handleSubmit(handleVerify)}>
          <RHFPasswordField
            className="mt-4"
            name="password"
            label={t('resetPassword.newPassword')}
          />

          <RHFPasswordField
            className="mt-4"
            name="confirmPassword"
            label={t('register.confirmPassword')}
          />

          <LoadingButton
            className="mt-6"
            type="submit"
            variant="contained"
            fullWidth
            loading={loading}
          >
            {t('common.submit')}
          </LoadingButton>
        </FormProvider>

        <div className="mt-3 text-center">
          <Link
            className="typo-body2 text-white underline"
            to={`/login?redirectTo=${redirectTo}`}
          >
            {t('forgotPassword.backToLogin')}
          </Link>
        </div>
      </div>
    </AuthLayout>
  )
}
