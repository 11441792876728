import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

export type PasswordFieldProps = Partial<TextFieldProps>

export const PasswordField: FC<PasswordFieldProps> = (textFieldProps) => {
  const { t } = useTranslation()

  const [showPassword, setShowPassword] = useState(false)

  return (
    <TextField
      label={t('common.password')}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...textFieldProps}
    />
  )
}
