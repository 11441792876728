import i18next, { InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import enTranslation from './translations/en.json'
import fiTranslation from './translations/fi.json'
import deTranslation from './translations/de.json'
import noTranslation from './translations/no.json'
import svTranslation from './translations/sv.json'
import ptBrTranslation from './translations/pt-BR.json'
import itTranslation from './translations/it.json'
import frTranslation from './translations/fr.json'
import daTranslation from './translations/da.json'
import nlTranslation from './translations/nl.json'
import esTranslation from './translations/es.json'

export const resources = {
  en: {
    translation: enTranslation,
  },
  fi: {
    translation: fiTranslation,
  },
  de: {
    translation: deTranslation,
  },
  no: {
    translation: noTranslation,
  },
  sv: {
    translation: svTranslation,
  },
  'pt-BR': {
    translation: ptBrTranslation,
  },
  it: {
    translation: itTranslation,
  },
  fr: {
    translation: frTranslation,
  },
  da: {
    translation: daTranslation,
  },
  nl: {
    translation: nlTranslation,
  },
  es: {
    translation: esTranslation,
  },
}

i18next
  // Enable automatic language detection
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // Standard language used
    lng: 'en',
    fallbackLng: 'en',
    debug: true,
    resources,
    // Detects and caches a cookie from the language provided
    detection: {
      order: ['localStorage'],
      cache: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  } as InitOptions)

export default i18next
