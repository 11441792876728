import Cookies from 'js-cookie'
import { CONFIG } from '../constants'
import { COOKIE_ITEM } from '../types/enums'

export class CookieService {
  static get(name: COOKIE_ITEM) {
    return Cookies.get(name)
  }

  static set(name: COOKIE_ITEM, value: string, remember?: boolean) {
    Cookies.set(name, value, {
      domain: CONFIG.BASE_DOMAIN,
      path: '/',
      expires: remember ? 7 : undefined,
    })
  }

  static remove(name: COOKIE_ITEM) {
    Cookies.remove(name, {
      domain: CONFIG.BASE_DOMAIN,
      path: '/',
    })
  }
}
