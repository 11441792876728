import axios, { AxiosRequestConfig, Method } from 'axios'
import { CONFIG } from '../constants'

export const abortController = new AbortController()

const http = axios.create({
  baseURL: CONFIG.API_SERVER,
  signal: abortController.signal,
})

export class HttpService {
  static get<T = any>(
    url: string,
    queries: any = {},
    config?: AxiosRequestConfig
  ) {
    return HttpService.request<T>('GET', url, { params: queries, ...config })
  }

  static post<T = any>(
    url: string,
    data: any = {},
    config?: AxiosRequestConfig
  ) {
    return HttpService.request<T>('POST', url, { data, ...config })
  }

  static put<T = any>(
    url: string,
    data: any = {},
    config?: AxiosRequestConfig
  ) {
    return HttpService.request<T>('PUT', url, { data, ...config })
  }

  static patch<T = any>(
    url: string,
    data: any = {},
    config?: AxiosRequestConfig
  ) {
    return HttpService.request<T>('PATCH', url, { data, ...config })
  }

  static delete<T = any>(
    url: string,
    data: any = {},
    config?: AxiosRequestConfig
  ) {
    return HttpService.request<T>('DELETE', url, { data, ...config })
  }

  static request<T = any>(
    method: Method,
    url: string,
    data?: AxiosRequestConfig
  ): Promise<T> {
    return http
      .request<T>({
        method,
        url,
        ...data,
      })
      .then((res) => res.data)
  }
}
