export const LANGUAGES = [
  { name: 'English', code: 'en' },
  { name: 'Suomi', code: 'fi' },
  { name: 'Deutsch', code: 'de' },
  { name: 'Norsk', code: 'no' },
  { name: 'Svenska', code: 'sv' },
  { name: 'Português (Brasil)', code: 'pt-BR' },
  { name: 'Italiano', code: 'it' },
  { name: 'Français', code: 'fr' },
  { name: 'Dansk', code: 'da' },
  { name: 'Nederlands', code: 'nl' },
  { name: 'Español', code: 'es' },
]
