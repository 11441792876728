import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'

import {
  FormProvider,
  RHFCheckbox,
  RHFPasswordField,
  RHFTextField,
} from '../../components/RHFControls'

import { CONFIG } from '../../constants'
import useQueryValue from '../../hooks/useQueryValue'
import { AuthLayout } from '../../layouts'
import { AuthService, CookieService } from '../../services'
import { COOKIE_ITEM } from '../../types/enums'

type FormData = {
  email: string
  password: string
  remember?: boolean
}

export const Login = () => {
  const { t } = useTranslation()
  const redirectTo = useQueryValue('redirectTo')

  const [loading, setLoading] = useState(false)

  const formSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('validation.invalidEmail'))
      .required(t('validation.required')),
    password: yup.string().required(t('validation.required')),
    remember: yup.boolean(),
  })

  const defaultValues = {
    email: '',
    password: '',
    remember: false,
  }

  const methods = useForm<FormData>({
    resolver: yupResolver(formSchema),
    defaultValues,
  })

  const { setError, handleSubmit } = methods

  const handleLogin = async (data: FormData) => {
    setLoading(true)
    try {
      const { email, password } = data
      const response = await AuthService.login(email, password)

      CookieService.set(
        COOKIE_ITEM.ACCESS_TOKEN,
        response.access_token,
        data.remember
      )
      CookieService.set(
        COOKIE_ITEM.REFRESH_TOKEN,
        response.access_token,
        data.remember
      )

      window.location.href = redirectTo || CONFIG.DEFAULT_REDIRECT_URL
    } catch (error: any) {
      setError('password', {
        ...error,
        message: error?.response?.status
          ? t('toast.invalidLoginCredential')
          : t('toast.networkError'),
      })
    }
    setLoading(false)
  }

  return (
    <AuthLayout title={t('login.title')}>
      <div className="w-full">
        <FormProvider methods={methods} onSubmit={handleSubmit(handleLogin)}>
          <RHFTextField name="email" label={t('common.email')} fullWidth />

          <RHFPasswordField
            className="mt-4"
            name="password"
            label={t('common.password')}
          />

          <RHFCheckbox
            className="mt-1"
            name="remember"
            label={t('login.rememberMe')}
          />

          <LoadingButton
            className="mt-4"
            type="submit"
            variant="contained"
            fullWidth
            loading={loading}
          >
            {t('common.login')}
          </LoadingButton>
        </FormProvider>

        <div className="typo-body2 mt-3 text-center text-white underline">
          <Link to={`/forgot-password?redirectTo=${redirectTo}`}>
            {t('login.forgotPassword')}
          </Link>
        </div>

        <div className="flex-center typo-body2 mt-3 gap-2 text-center">
          <span className="text-sm text-neutral-400">
            {t('login.signUpLink')}
          </span>
          <Link
            className="text-white underline"
            to={`/register?redirectTo=${redirectTo}`}
          >
            {t('common.signUp')}
          </Link>
        </div>
      </div>
    </AuthLayout>
  )
}
