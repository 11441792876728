import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'

import { FormProvider, RHFTextField } from '../../components/RHFControls'
import { ForgotPasswordVerification } from './ForgotPasswordVerification'
import { ResetPassword } from './ResetPassword'

import useQueryValue from '../../hooks/useQueryValue'
import { AuthLayout } from '../../layouts'
import { AuthService } from '../../services'

type FormData = {
  email: string
}

export const ForgotPassword = () => {
  const { t } = useTranslation()
  const redirectTo = useQueryValue('redirectTo')

  const [loading, setLoading] = useState(false)
  const [otpCode, setOtpCode] = useState<string>()
  const [verificationCode, setVerificationCode] = useState<string>()

  const formSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('validation.invalidEmail'))
      .required(t('validation.required')),
  })

  const defaultValues = {
    email: '',
    password: '',
    confirmPassword: '',
  }

  const methods = useForm<FormData>({
    resolver: yupResolver(formSchema),
    defaultValues,
  })

  const { setError, handleSubmit } = methods
  const formData = methods.watch()

  const handleForgotPassword = async (data: FormData) => {
    setLoading(true)
    try {
      const { email } = data
      const response = await AuthService.getOtpResetPassword(email)
      setOtpCode(response.otp)
    } catch (error: any) {
      setError('email', {
        ...error,
        message: error?.response?.status
          ? t('toast.sendingRequestFailed')
          : t('toast.networkError'),
      })
    }
    setLoading(false)
  }

  if (otpCode && verificationCode) {
    return (
      <ResetPassword
        email={formData.email}
        otpCode={otpCode}
        verificationCode={verificationCode}
      />
    )
  }

  if (otpCode) {
    return <ForgotPasswordVerification onSubmit={setVerificationCode} />
  }

  return (
    <AuthLayout
      title={t('forgotPassword.title')}
      description={t('forgotPassword.description')}
    >
      <div className="w-full">
        <FormProvider
          methods={methods}
          onSubmit={handleSubmit(handleForgotPassword)}
        >
          <RHFTextField name="email" label={t('common.email')} fullWidth />

          <LoadingButton
            className="mt-6"
            type="submit"
            variant="contained"
            fullWidth
            loading={loading}
          >
            {t('common.next')}
          </LoadingButton>
        </FormProvider>

        <div className="mt-3 text-center">
          <Link
            className="typo-body2 text-white underline"
            to={`/login?redirectTo=${redirectTo}`}
          >
            {t('forgotPassword.backToLogin')}
          </Link>
        </div>
      </div>
    </AuthLayout>
  )
}
